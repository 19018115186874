import "./SlotNumber.css";
import { useState, useEffect } from "react";

function SlotNumber(props) {
    //Generate a placeholder number just for aesthetics on the first run
    const numberPlaceholder = props.number
        .toString()
        .split("")
        .map((e) => (e >= 5 ? 0 : "a"))
        .join("");

    //Set states
    const [startDelay, setStartDelay] = useState(props.startDelay || 0);
    const [number, setNumber] = useState(
        props.autoStart === false ? props.number.toString() : numberPlaceholder
    );

    //Delay the counter update (if applicable)
    useEffect(() => {
        let timeout = setTimeout(() => {
            setNumber(props.number.toString());
            if (startDelay > 0) setStartDelay(0);
        }, startDelay || props.delay || 0);
        return () => clearTimeout(timeout);
    }, [props.number, props.delay, startDelay]);

    return (
        <span
            className={`roulette-container ${props.className}`}
            style={{
                fontSize: `${props.height || 30}px`,
                lineHeight: `${props.height || 30}px`,
                height: `${props.height || 30}px`,
            }}
        >
            {number.split("").map((number, index) => {
                return (
                    <Roulette
                        key={index}
                        height={props.height}
                        duration={props.duration}
                        number={number}
                        index={number.split("").length - index}
                    />
                );
            })}
        </span>
    );
}

function Roulette(props) {
    const { number, index, height } = props;
    const transformHeight = (number === "a" ? 10 : number) * height;

    return (
        <span
            className="roulette"
            style={{
                transition: `all ${index * 150 + 600}ms, color 200ms`,
                transform: `translateY(-${transformHeight}px)`,
            }}
        >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, ","].map((num, i) => (
                <span key={i}>{num}</span>
            ))}
        </span>
    );
}

export default SlotNumber;
