import Cookies from "js-cookie";
import { TypeAnimation } from "react-type-animation";
import { useState, useEffect } from "react";
import SlotNumber from "./components/SlotNumber/SlotNumber";
import useWebSocket from "react-use-websocket";
import "./App.css";

function App() {
    const colors = [
        "#f00",
        "#FF3A6E",
        "#6a00ff",
        "#00b209",
        "#0048ff",
        "#ffa600",
    ];

    const [colorIndex, setColorIndex] = useState(Cookies.get("color") || 0);
    const [vugnaesOption, setVugnaesOption] = useState(0);
    const [vugnaesTop, setVugnaesTop] = useState(0);
    const [vugnaesData, setVugnaesData] = useState(null);
    const [vugnaesClose, setVugnaesClose] = useState(false);

    const [vugnaesModal, setVugnaesModal] = useState(false);

    useWebSocket(
        window.location.protocol === "http:"
            ? `ws://${window.location.hostname}:8080/ws`
            : `wss://${window.location.hostname}/ws`,
        {
            onMessage: (msg) => {
                setVugnaesData(JSON.parse(msg.data));
            },
            onError: (event) => {
                console.error(event);
            },
            shouldReconnect: (closeEvent) => true,
            reconnectInterval: 3000,
        }
    );

    useEffect(() => {
        setVugnaesClose(true);
        setTimeout(() => {
            setVugnaesClose(false);
        }, 400);
    }, [vugnaesOption]);

    return (
        <div
            className="App"
            style={{
                color: colors[colorIndex],
            }}
        >
            <div
                className={`vugnaes-modal ${vugnaesModal ? "shown" : ""}`}
            ></div>

            <VugnaesHeader onClick={() => {} /*setVugnaesModal(true)*/} />

            <div
                className={`vugnaes-info-container ${
                    vugnaesData && !vugnaesClose ? "shown" : ""
                } ${vugnaesOption === 2 ? "top" : ""}`}
            >
                <div
                    className={`vugnaes-top ${
                        vugnaesOption === 2 ? "top" : ""
                    }`}
                >
                    <div className="vugnaes-top-select">
                        {["Viewers", "Canais"].map((e, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`vugnaes-select-option ${
                                        vugnaesTop === i ? "selected" : ""
                                    }`}
                                    style={{
                                        backgroundColor:
                                            vugnaesTop === i
                                                ? colors[colorIndex]
                                                : "transparent",
                                    }}
                                    onClick={() => setVugnaesTop(i)}
                                >
                                    {e}
                                </div>
                            );
                        })}
                    </div>

                    <VugnaesTop
                        vugnaesTop={vugnaesTop}
                        vugnaesData={vugnaesData}
                        vugnaesOption={vugnaesOption}
                    />
                </div>

                <VugnaesNumber
                    vugnaesData={vugnaesData}
                    vugnaesOption={vugnaesOption}
                />
            </div>
            <div
                className={`vugnaes-select-info ${vugnaesData ? "shown" : ""}`}
            >
                {["Total", "Hoje", "Top 10"].map((e, i) => {
                    return (
                        <div
                            className={`vugnaes-select-option ${
                                vugnaesOption === i ? "selected" : ""
                            }`}
                            style={{
                                backgroundColor:
                                    vugnaesOption === i
                                        ? colors[colorIndex]
                                        : "transparent",
                            }}
                            onClick={() => setVugnaesOption(i)}
                            key={i}
                        >
                            {e}
                        </div>
                    );
                })}
            </div>
            <div className="color-select">
                {colors.map((color, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                Number(colorIndex) === Number(index)
                                    ? "selected"
                                    : ""
                            }
                            style={{ backgroundColor: color }}
                            onClick={() => {
                                setColorIndex(index);
                                Cookies.set("color", index, { expires: 1000 });
                            }}
                        ></div>
                    );
                })}
            </div>
            <a
                className="profilelink"
                href="https://twitter.com/vinnytasso"
                target="_blank"
                rel="noreferrer"
            >
                @vinnytasso
            </a>
        </div>
    );
}

function VugnaesHeader(props) {
    const [cursor, setCursor] = useState(true);
    const [vugnaesPublicando, setVugnaesPublicando] = useState(false);
    const [resetVugnaes, setResetVugnaes] = useState(false);

    return (
        <div
            className={`vugnaes-header ${cursor ? "" : "hidden"}`}
            onClick={props.onClick}
        >
            {!resetVugnaes ? (
                <TypeAnimation
                    sequence={[
                        1000,
                        "Vugnaes ",
                        1000,
                        "Vugnaes sreo",
                        () => setCursor(false),
                        1000,
                        () => setVugnaesPublicando(true),
                        2000,
                        () => {
                            setTimeout(() => setResetVugnaes(false), 1000);
                            setVugnaesPublicando(false);
                            setResetVugnaes(true);
                            setCursor(true);
                        },
                    ]}
                    speed={600}
                />
            ) : (
                <div className="vugnaes-placeholder">Vugnaes sreo|</div>
            )}
            <div
                className={`vugnaes-publicando ${
                    vugnaesPublicando ? "shown" : ""
                }`}
            >
                Publicando...
            </div>
        </div>
    );
}

function VugnaesTop(props) {
    const { vugnaesData, vugnaesTop, vugnaesOption } = props;
    const [top, setTop] = useState(0);
    const [hide, setHide] = useState(false);

    useEffect(() => {
        setHide(true);
        setTimeout(() => {
            setTop(vugnaesTop);
            setHide(false);
        }, 500);
    }, [vugnaesTop]);

    return (
        <div className={`vugnaes-top-list ${hide ? "hidden" : ""}`}>
            {vugnaesData
                ? (top === 0
                      ? vugnaesData.topUsers
                      : vugnaesData.topChannels
                  ).map((e, i) => {
                      return (
                          <div
                              className="vugnaes-top-item"
                              key={top === 0 ? e.person : e.channel}
                          >
                              <span>
                                  <span className="vugnaes-top-number">
                                      {i + 1}
                                  </span>
                                  {top === 0
                                      ? e.person
                                      : e.channel.replace("#", "")}
                              </span>
                              <span className="vugnaes-top-qtty">
                                  {vugnaesOption === 2 ? (
                                      <SlotNumber
                                          number={e.qtty}
                                          height={16}
                                          startDelay={500}
                                      />
                                  ) : (
                                      ""
                                  )}
                              </span>
                          </div>
                      );
                  })
                : ""}
        </div>
    );
}

function VugnaesNumber(props) {
    const { vugnaesData, vugnaesOption } = props;

    const [option, setOption] = useState(vugnaesOption);

    useEffect(() => {
        setTimeout(() => {
            setOption(vugnaesOption);
        }, 400);
    }, [vugnaesOption]);

    return (
        <div
            className={`vugnaes-number-container ${
                vugnaesOption !== 2 ? "shown" : ""
            }`}
        >
            {" "}
            {vugnaesData ? (
                <SlotNumber
                    key={option}
                    number={
                        option === 0 ? vugnaesData.total : vugnaesData.today
                    }
                    height={50}
                    startDelay={500}
                    className={`vugnaes-number`}
                />
            ) : (
                ""
            )}
            <div
                className={`vugnaes-total ${
                    vugnaesOption !== 2 ? "shown" : ""
                }`}
            >
                Total de Vugnaes sreo{" "}
                <span className="vugnaes-info">
                    ?
                    <div className="vugnaes-info-bubble">
                        Dados coletados em tempo real de diversas fontes da
                        internet, incluindo o chat de streamers como{" "}
                        <a
                            href="https://www.twitch.tv/omeiaum"
                            target="_blank"
                            rel="noreferrer"
                        >
                            oMeiaUm
                        </a>{" "}
                        e{" "}
                        <a
                            href="https://www.twitch.tv/felps"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Felps
                        </a>
                    </div>
                </span>
            </div>
        </div>
    );
}

function delay(ms) {
    return new Promise((res) => {
        setTimeout(() => res(), ms);
    });
}

export default App;
